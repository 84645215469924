import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  // lazy,
  // Suspense,
  useEffect,
} from 'react';
import { useSDK, MetaMaskProvider } from '@metamask/sdk-react';
// import { encode } from '@metamask/abi-utils';
// import { bytesToHex } from '@metamask/utils';
import { Buffer } from 'buffer';
import { chainNames, WRONG_NETWORK_ID } from '../constants';
import { ChainInfo, WithdrawRequestSignature } from '../types';
import { ethers } from 'ethers';
import { encode } from '@metamask/abi-utils';
import { bytesToHex } from '@metamask/utils';

interface WalletContextProps {
  networkName: string | null;
  account: string | undefined;
  chainId: string | undefined;
  connecting: boolean;
  // isConnected: boolean;
  isSupportedChain: boolean;
  connectAndSign: (message: string) => any;
  connect: () => Promise<string[] | undefined>;
  sign: (payload: string) => Promise<any>;
  terminate: () => void;
  changeNetwork: (hexChainId: string) => Promise<any>;
  addNetwork: (network: ChainInfo) => Promise<any>;
  deposit: (
    amount: number,
    tokenAddress: string,
    contractAddress: string,
    contractMethod: string,
    comment?: string,
  ) => Promise<string>;
  withdraw: (
    userAddress: string,
    amount: number,
    nonce: number,
    deadline: number,
    tokenAddress: string,
    contractMethod: string,
    signature: WithdrawRequestSignature,
  ) => Promise<string>;
}

const WalletContext = createContext<WalletContextProps | undefined>(undefined);

interface WalletManagerProps {
  children: ReactNode;
}

interface VendorWalletProps {
  children: ReactNode;
}

export const VendorWalletProvider: React.FC<VendorWalletProps> = ({ children }) => {
  return (
    <MetaMaskProvider
      debug={false}
      sdkOptions={{
        logging: {
          developerMode: false,
        },
        checkInstallationImmediately: false, // This will automatically connect to MetaMask on page load
        dappMetadata: {
          name: 'ChessNode Game',
          url: window.location.protocol + '//' + window.location.host,
        },
      }}>
      {children}
    </MetaMaskProvider>
  );
};

const WalletProvider: React.FC<WalletManagerProps> = ({ children }) => {
  const ethereum = window.ethereum!;

  const { sdk, connecting, provider, chainId, account } = useSDK();

  const [networkName, setNetworkName] = useState<string | null>(null);

  const isSupportedChain: boolean = chainId !== undefined && chainNames[chainId] !== undefined;

  useEffect(() => {
    if (chainId) {
      const name = chainNames[chainId] || WRONG_NETWORK_ID;
      setNetworkName(name);
    }
  }, [chainId]);

  const connectAndSign = async (message: string): Promise<any> => {
    try {
      return await sdk?.connectAndSign({
        msg: message,
      });
    } catch (err) {
      console.error(`failed to connect..`, err);
    }
  };

  const connect = async (): Promise<string[] | undefined> => {
    console.log('connect');
    try {
      const result = await sdk?.connect();
      console.log('result', result);
      return result;
    } catch (err) {
      console.error(`failed to connect..`, err);
    }
  };

  const sign = async (payload: string): Promise<any> => {
    console.log('sign');
    try {
      if (!provider) {
        throw new Error('No provider connected');
      }
      const from = provider.getSelectedAddress();
      const hexMessage = '0x' + Buffer.from(payload, 'utf8').toString('hex');
      const result = await provider.request({
        method: 'personal_sign',
        params: [hexMessage, from],
      });
      console.log(`result: ${result}`);
      return result;
    } catch (err) {
      console.error(`sign error: ${err}`);
    }
  };

  const terminate = (): Promise<void> | undefined => {
    return sdk?.terminate();
  };

  const changeNetwork = async (hexChainId: string): Promise<any> => {
    try {
      const response = await provider?.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: hexChainId }],
      });
      console.debug(`response`, response);
    } catch (err) {
      console.error(err);
    }
  };

  const addNetwork = async (network: ChainInfo): Promise<any> => {
    try {
      const response = await provider?.request({
        method: 'wallet_addEthereumChain',
        params: [network],
      });
      return response;
    } catch (err) {
      console.error(err);
    }
  };

  const deposit = async (
    amount: number,
    tokenAddress: string,
    contractAddress: string,
    contractMethod: string,
    comment: string | null = null,
  ): Promise<string> => {
    const tokenAmount = ethers.parseEther(amount.toString());

    let data =
      contractMethod +
      contractAddress.slice(2).padStart(64, '0') +
      tokenAmount.toString(16).padStart(64, '0');
    if (comment) {
      data += Buffer.from(comment, 'utf8').toString('hex');
    }

    const transactionParameters = {
      to: tokenAddress,
      from: ethereum.selectedAddress,
      data,
    };

    try {
      const txHash = await provider?.request({
        method: 'eth_sendTransaction',
        params: [transactionParameters],
      });
      console.log(`Token transaction sent! Hash: ${txHash}.`);
      if (!txHash) {
        throw new Error('Transaction failed to send') as Error;
      }
      return txHash as string;
    } catch (error) {
      console.error('Token transaction failed:', error);
      throw error;
    }
  };

  const withdraw = async (
    userAddress: string,
    amount: number,
    nonce: number,
    deadline: number,
    contractAddress: string,
    contractMethod: string,
    signature: WithdrawRequestSignature,
  ): Promise<string> => {
    console.log('withdraw',
      userAddress,
      amount,
      nonce,
      deadline,
      contractAddress,
      contractMethod,
      signature
    );

    const encoded = encode(
      ['uint256', 'uint256', 'uint256', 'uint8', 'bytes32', 'bytes32'],
      [amount, deadline, nonce, signature.v, signature.r, signature.s]
    );
    const encodedHash = bytesToHex(encoded);

    const data = contractMethod + encodedHash.substring(2);
    console.log('data', data);

    console.log('ethereum.selectedAddress', ethereum.selectedAddress);
    const transactionParameters = {
      to: contractAddress,
      from: userAddress,
      data,
    };

    try {
      const txHash = await provider?.request({
        method: "eth_sendTransaction",
        params: [transactionParameters],
      });
      console.log("withdrawal transaction signed", txHash);

      if (!txHash) {
        throw new Error('Transaction failed to send') as Error;
      }
      return txHash as string;
    } catch (error) {
      console.error("Token transaction failed:", error);
      throw error;
    }
  };

  return (
    <WalletContext.Provider
      value={{
        networkName,
        chainId,
        account,
        connecting,
        isSupportedChain,
        connectAndSign,
        connect,
        sign,
        terminate,
        changeNetwork,
        addNetwork,
        deposit,
        withdraw,
      }}>
      {children}
    </WalletContext.Provider>
  );
};

export const useWallet = (): WalletContextProps => {
  const context = useContext(WalletContext);
  if (!context) {
    throw new Error('useWallet must be used within a WalletProvider');
  }
  return context;
};

export default WalletProvider;
