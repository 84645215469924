import { useEffect } from 'react';

export const useTelegramClose = () => {
  useEffect(() => {
    if (window.Telegram?.WebApp) {
      window.Telegram.WebApp.BackButton.hide();
      return () => {
        window.Telegram.WebApp.BackButton.show();
      };
    }
  }, []);
};

export default useTelegramClose;
